import { colors } from 'fitify-ui'
import { CInfoIcon } from 'fitify-ui/src/Icon/outline'
import React from 'react'
import { renderToString } from 'react-dom/server'
import styled from 'styled-components'

import { FieldLabelProps } from '@/components/forms/Field.Types'

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  font-family: 'Graphik LCG Web', sans-serif;
  line-height: 13px;
`

const StyledRequired = styled.span`
  padding-left: 2px;
  color: ${colors.Red400};
`

const StyledTooltipWrapper = styled.span`
  padding-left: 2px;
  cursor: pointer;
`

const StyledTooltip = styled.div`
  font-size: 12px;
`

export const FieldLabel: React.FC<FieldLabelProps> = ({
  label,
  labelFor,
  isRequired = false,
  tooltip,
}) => {
  return (
    <StyledLabel htmlFor={labelFor}>
      {label}
      {isRequired && <StyledRequired>*</StyledRequired>}
      {tooltip && (
        <StyledTooltipWrapper
          data-tooltip-id="app-tooltip-instance"
          data-tooltip-html={renderToString(
            typeof tooltip === 'string' ? (
              <StyledTooltip dangerouslySetInnerHTML={{ __html: tooltip }} />
            ) : (
              <StyledTooltip>{tooltip}</StyledTooltip>
            )
          )}
          data-tooltip-place="right"
          data-tooltip-offset={0}
        >
          <CInfoIcon color={'Gray500'} />
        </StyledTooltipWrapper>
      )}
    </StyledLabel>
  )
}
